import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H6, TextSm, Text, MarkdownHandler } from '@system'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '0px',
  },
  outer: {
    border: '1px solid  ',
    borderColor: theme.palette.border.light,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: ({ blok }) => !!blok.mobileColumnWebinar && 'column',
    },
  },

  gridLeft: {
    width: '30%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: ({ blok }) => !!blok.mobileColumnWebinar && '100%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      borderRadius: ({ blok }) =>
        !!blok.userPicture && get(blok.userPicture, '[0].noBorderRadius')
          ? '0%'
          : '50%',
    },
  },
  gridRight: {
    width: '66%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: ({ blok }) => (blok.mobileColumnWebinar ? '100%' : '60%'),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    flexDirection: 'column',
    justifyContent: 'flexStart',
    alignItems: 'flexStart',
  },
  name: {
    color: ({ blok }) =>
      (!!blok.titleColor && blok.titleColor.color) ||
      theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: '16px',
    display: 'block',
    textAlign: 'center',
  },
  description: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightRegular,
    padding: '16px 0px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },
    display: 'block',
    textAlign: 'center',
  },
  noPaddingDescription: {
    padding: '0px',
  },
  descriptionInfo: {
    fontSize: '16px',
    '& p a': {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.tertiary,
      width: 'fit-content',
    },
  },
  starRating: {
    marginTop: '10px',
  },
}))

const ReviewCard = (props) => {
  const classes = useStyles(props)
  const {
    largerDescriptionText,
    userPicture,
    logoReview,
    name,
    description,
    descriptionInfo,
    starReviewImage,
  } = props.blok

  const Description = ({ children, ...otherProps }) => {
    return largerDescriptionText ? (
      <Text {...otherProps}>{children}</Text>
    ) : (
      <TextSm {...otherProps}>{children}</TextSm>
    )
  }

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.outer}>
        <Box className={classes.gridLeft}>
          {!!userPicture.length && renderBloks(userPicture)}
          <H6 className={classes.name}>{name}</H6>
          <Description className={classes.description}>
            {description}
          </Description>
        </Box>

        <Box className={classes.gridRight}>
          {!!logoReview.length && renderBloks(logoReview)}
          <Box className={classes.starRating}>
            {!!logoReview.length && renderBloks(starReviewImage)}
          </Box>
          <TextSm className={classes.descriptionInfo}>
            <MarkdownHandler>{descriptionInfo}</MarkdownHandler>
          </TextSm>
        </Box>
      </Box>
    </SbEditable>
  )
}

export default ReviewCard
